import closeWindowAtom from "./uiState/closeWindowAtom";
import activeWindowAtom from "app/uiState/activeWindowAtom";
import { WindowContext } from "app/WindowStateProvider";
import getWindowAtom from "./uiState/getWindowAtom";
import { useContext, useCallback } from "react";
import { useAtomValue, useAtom, atom } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { useIsMobileOrTablet } from "app/jm";
import type {
  UIProps,
  ComponentState,
  ComponentName,
} from "./componentOptions";
import componentStackAtom from "./uiState/mobile/componentStackAtom";
import { assert } from "lib/util/assert";

// This only exists because we cannot conditionally call useAtom
// if the windowId is not found in the context.
const defaultComponentAtom = atom<ComponentState<ComponentName> | null>(null);

// Returns the state of the Current window (ie the window within which this hook is called)
// not necessarily the active window
export function useCurrentComponent() {
  const isMobileOrTablet = useIsMobileOrTablet();

  const windowContextValue = useContext(WindowContext);

  const windowId = windowContextValue?.windowId || null;

  const [windowComponentState, setWindowComponentState] = useAtom(
    windowId ? getWindowAtom(windowId) : defaultComponentAtom
  );

  //console.log("windowComponentState", windowComponentState);

  const mobileComponentStack = useAtomValue(componentStackAtom);

  const [mobileComponentState, setMobileComponentState] = useAtom(
    mobileComponentStack.length > 0
      ? mobileComponentStack.slice(-1)[0]!
      : defaultComponentAtom
  );

  const componentState = isMobileOrTablet
    ? mobileComponentState
    : windowComponentState;

  // ComponentState should not be null here
  assert(componentState);

  /**
   * Set an individual UI prop
   */
  const setUIProp = useCallback(
    <K extends keyof UIProps>(prop: K, value: UIProps[K]) => {
      const setter = isMobileOrTablet
        ? setMobileComponentState
        : setWindowComponentState;
      return setter((prevState) => {
        if (prevState === null) {
          throw new Error("Component not found");
        }
        return {
          ...prevState,
          uiProps: {
            ...prevState.uiProps,
            [prop]: value,
          },
        };
      });
    },
    [isMobileOrTablet, setMobileComponentState, setWindowComponentState]
  );

  /**
   * Takes an object of ui props and merges them into the current props
   */
  const updateUIProps = useCallback(
    (newUIProps: Partial<UIProps>) => {
      const setter = isMobileOrTablet
        ? setMobileComponentState
        : setWindowComponentState;
      return setter((prevState) => {
        if (prevState === null) {
          throw new Error("Component not found");
        }
        return {
          ...prevState,
          uiProps: {
            ...prevState.uiProps,
            ...newUIProps,
          },
        };
      });
    },
    [isMobileOrTablet, setMobileComponentState, setWindowComponentState]
  );

  const close = useAtomCallback(
    useCallback(
      (get, set) => {
        if (isMobileOrTablet) {
          const currentStack = get(componentStackAtom);
          if (currentStack.length > 0) {
            const updatedStack = currentStack.slice(0, -1);
            set(componentStackAtom, updatedStack);
          }
        } else if (windowId) {
          set(closeWindowAtom, windowId);
        }
      },
      [isMobileOrTablet, windowId]
    )
  );

  const setAsActive = useAtomCallback(
    useCallback(
      (get, set) => {
        set(activeWindowAtom, windowId);
      },
      [windowId]
    )
  );

  // This used to be at the top but violated rules-of-hooks (don't use hooks after early return)
  // And would throw eslint errors
  if (!isMobileOrTablet && windowContextValue === null) {
    //return null;
    throw new Error("WindowContext not found");
  }

  // "as const" is required to prevent the type from being widened to Array<ComponentState | SetStateAction<ComponentState>>
  // an instead as the specific tuple [ComponentState, SetStateAction<ComponentState>]
  return {
    componentState,
    setUIProp,
    updateUIProps,
    close,
    setAsActive,
  } as const;
}
